import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import Videosmartphone from "../../assets/video/videosmart.mp4"
import { StaticImage } from "gatsby-plugin-image"

const posterVideo = (
  <StaticImage src="../../images/poster.jpg" alt="hero poster" />
)

const Videosmart = () => {
  const { t } = useTranslation()
  return (
    <div className="flex justify-center items-center  bg-azul">
      <video
        autoPlay
        muted
        loop
        playsInline
        poster={posterVideo}
        className="object-cover w-screen h-[450px] opacity-40"
      >
        <source src={Videosmartphone} type="video/mp4" />
      </video>
      <div className="absolute px-7">
        <h1
          className="text-4xl text-blanco font-bold text-center mb-4"
          dangerouslySetInnerHTML={{ __html: t("home.titulo") }}
        />
        <h2 className="text-blanco text-xl text-center">
          {t("home.subtitulo")}
        </h2>
      </div>
    </div>
  )
}

export default Videosmart
