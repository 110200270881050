import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "gatsby"

const BotonPeque = props => {
  const { t } = useTranslation()
  return (
    <div className="self-start">
      {props.url && (
        <Link
          to={t(props.url)}
          className="flex justify-center text-lg font-bold px-10 py-2  bg-azul text-blanco border-blanco border rounded-full hover:text-blanco hover:bg-azulClaro hover:border-azulClaro transition ease-in-out "
        >
          {t(props.texto)}
        </Link>
      )}
      {props.urlExterna && (
        <a
          href={props.urlExterna}
          rel="noopener noreferrer"
          target="_blank"
          aria-label="link"
          className="flex justify-center mt-4 py-2 w-40 text-azul border-azul bg-blanco border rounded-full hover:text-blanco hover:bg-azul transition ease-in-out text-md"
          dangerouslySetInnerHTML={{ __html: t("botonPeque") }}
        />
      )}
    </div>
  )
}

export default BotonPeque
