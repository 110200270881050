import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { useTranslation } from "gatsby-plugin-react-i18next"

const Masinfo = () => {
  const { t } = useTranslation()
  return (
    <section className="relative flex flex-col justify-center items-start h-96">
      <div className="block h-28 w-full bg-gradient-to-b from-white to-transparent translate-y-20 z-10"></div>
      <StaticImage
        src="../images/calltoaction.jpg"
        alt="call to action"
        className="h-full"
      />
      <article className="absolute top-0 right-0 left-0 bottom-0 flex flex-col items-center justify-start z-10">
        <p className="text-azul text-3xl text-center mt-10 md:mt-20 px-10">
          {t("masinfo.texto")}
          <span className="animate-pulse">
            <Link to={t("rutas.contacto")} className="font-bold text-azulClaro">
              &nbsp;{t("masinfo.aqui")}
            </Link>
          </span>
        </p>
      </article>
    </section>
  )
}

export default Masinfo
