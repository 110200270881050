import React, { useState, useEffect } from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { StaticImage } from "gatsby-plugin-image"

const Comunicaciones = () => {
  const { t } = useTranslation()

  const [comunicaciones, setComunicaciones] = useState("")

  useEffect(() => {
    window.addEventListener("scroll", handleComunicaciones)
  }, [])

  const handleComunicaciones = () => {
    const position = window.pageYOffset

    if (position >= 2500) {
      setComunicaciones("md:animate-comunicaciones")
    } else {
      setComunicaciones("")
    }
  }

  return (
    <section
      className="flex flex-col justify-center items-center lg:px-14  lg:my-10  "
      id="comunicaciones"
    >
      <article className="container max-w-8xl grid grid-cols-2 gap-x-10">
        <div className="w-full h-56 sm:h-auto overflow-hidden col-span-2  md:col-span-1">
          <StaticImage
            src="../../images/comunicaciones.jpg"
            className="w-full h-full"
            alt="technology"
          />
        </div>

        <div className="col-span-2 md:col-span-1 px-10 md:p-5">
          <h1
            className={`text-xl bg-azulClaro text-blanco font-bold px-8 py-2 rounded-lg inline-block mb-5 transform-gpu mt-7 md:mt-0 ${comunicaciones}`}
          >
            {t("home.comunicaciones.titulo")}
          </h1>
          <h2 className="text-3xl text-azul font-black uppercase">
            {t("home.comunicaciones.titulo2")}
          </h2>

          <p
            className="relative  text-azul-dark mt-5 pb-5 before:block before:w-3 before:h-3 before:bg-azul before:absolute before:top-2 before:-left-5 before:z-30 lg:col-span-2 lg:text-xl tracking-tight"
            dangerouslySetInnerHTML={{ __html: t("home.comunicaciones.texto") }}
          />
          <ul className="relative text-blanco font-bold pb-3 mb-5 md:pb-5 md:text-xl bg-azul px-7 md:px-10 w-full">
            <li className="list-disc list-outside pt-4 pb-2 border-b">
              {t("home.comunicaciones.lista.0")}
            </li>
            <li className="list-disc list-outside py-1 border-b">
              {t("home.comunicaciones.lista.1")}
            </li>
            <li className="list-disc list-outside py-1 border-b">
              {t("home.comunicaciones.lista.2")}
            </li>
            <li className="list-disc list-outside py-1 border-b">
              {t("home.comunicaciones.lista.3")}
            </li>
            <li className="list-disc list-outside py-1 border-b">
              {t("home.comunicaciones.lista.4")}
            </li>
            <li className="list-disc list-outside py-1 border-b">
              {t("home.comunicaciones.lista.5")}
            </li>
            <li className="list-disc list-outside py-1">
              {t("home.comunicaciones.lista.6")}
            </li>
          </ul>
          <p className="relative  text-azul-dark mt-5 pb-5 before:block before:w-3 before:h-3 before:bg-azul before:absolute before:top-2 before:-left-5 before:z-30 lg:col-span-2 lg:text-xl tracking-tight">
            {t("home.comunicaciones.texto2")}
          </p>
        </div>
      </article>
    </section>
  )
}

export default Comunicaciones
