import React, { useState, useEffect } from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { StaticImage } from "gatsby-plugin-image"

const Navegacion = () => {
  const { t } = useTranslation()
  const [animacion, setAnimacion] = useState("")

  useEffect(() => {
    window.addEventListener("scroll", handleAnimacion)
  }, [])

  const handleAnimacion = () => {
    const position = window.pageYOffset

    if (position >= 3400) {
      setAnimacion("md:animate-comunicaciones")
    } else {
      setAnimacion("")
    }
  }

  return (
    <section
      className="flex flex-col justify-center items-center lg:px-14 lg:my-10"
      id="navegacion"
    >
      <article className="container max-w-8xl grid grid-cols-2 gap-x-10">
        <div className="col-span-2  md:col-span-1 h-auto flex flex-col items-stretch overflow-hidden">
          <StaticImage
            src="../../images/navegacion.jpg"
            className="w-full h-56 col-span-2 md:col-span-1 md:h-full"
            alt="technology"
          />
        </div>

        <div className="col-span-2 md:col-span-1 px-10 md:p-5 ">
          <h1
            className={`text-xl bg-azulClaro text-blanco font-bold px-8 py-2 rounded-lg inline-block mb-5 transform-gpu  mt-7 md:mt-0 ${animacion}`}
          >
            {t("home.navegacion.titulo")}
          </h1>
          <h2 className="text-3xl text-azul font-black uppercase">
            {t("home.navegacion.titulo2")}
          </h2>

          <p
            className="relative text-azul-dark mt-5 pb-5 before:block before:w-3 before:h-3 before:bg-azul before:absolute before:top-2 before:-left-5 before:z-30 lg:col-span-2 lg:text-xl tracking-tight"
            dangerouslySetInnerHTML={{ __html: t("home.navegacion.texto") }}
          />
          <p
            className="relative text-azul-dark  pb-5 before:block before:w-3 before:h-3 before:bg-azul before:absolute before:top-2 before:-left-5 before:z-30 lg:col-span-2 lg:text-xl tracking-tight"
            dangerouslySetInnerHTML={{ __html: t("home.navegacion.texto2") }}
          />
          <ul className="relative text-blanco font-bold pb-3 mb-5 md:pb-5 md:text-xl bg-azul px-7 md:px-10 w-full">
            <li className="list-disc list-outside pt-4 pb-2 border-b border-blanco">
              {t("home.navegacion.lista.0")}
            </li>
            <li className="list-disc list-outside py-1 border-b border-blanco">
              {t("home.navegacion.lista.1")}
            </li>
            <li className="list-disc list-outside py-1 border-b border-blanco">
              {t("home.navegacion.lista.2")}
            </li>
            <li className="list-disc list-outside pt-2">
              {t("home.navegacion.lista.3")}
            </li>
          </ul>
        </div>
      </article>
    </section>
  )
}

export default Navegacion
