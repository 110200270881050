import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import SeaconnectVHero from "../../assets/video/seaconnectVHero.mp4"

const Video = () => {
  const { t } = useTranslation()
  return (
    <div className="relative flex justify-center items-center xl:mt-30 bg-azul">
      <video
        autoPlay
        muted
        loop
        playsInline
        className="object-cover w-screen h-[900px] opacity-60 "
      >
        <source src={SeaconnectVHero} type="video/mp4" />
      </video>
      <div className="absolute sm:w-3/4">
        <h1
          className="text-6xl text-blanco font-bold text-center mb-4"
          dangerouslySetInnerHTML={{ __html: t("home.titulo") }}
        />
        <h2 className="text-3xl text-blanco font-light text-center max-w-5xl mx-auto">
          {t("home.subtitulo")}
        </h2>
      </div>
    </div>
  )
}

export default Video
