import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Video from "../components/home/Video"
import Videosmart from "../components/home/Videosmart"
import { useTranslation } from "react-i18next"
import { useI18next } from "gatsby-plugin-react-i18next"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import Layout from "../components/Layout"
import Presentacion from "../components/home/Presentacion"
import Servicios from "../components/home/Servicios"
import Carrusel from "../components/home/Carrusel"
import Nosotros from "../components/home/Nosotros"
import Comunicaciones from "../components/home/Comunicaciones"
import Avit from "../components/home/Avit"
import Navegacion from "../components/home/Navegacion"
import Servicio from "../components/home/Servicio"
import Masinfo from "../components/Masinfo"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Flecha from "../assets/flecha.svg"

const IndexPage = () => {
  const { t } = useTranslation()
  const { language } = useI18next()
  const breakpoints = useBreakpoint()

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
  }, [])

  const handleScroll = () => {
    const position = window.pageYOffset
    if (position < 600) {
      setFlecha("opacity-0 invisible")
    } else {
      setFlecha("visible opacity-100")
    }
  }

  const [flecha, setFlecha] = useState("opacity-0 invisible")

  const seo = {
    title: t("home.seo.title"),
    description: t("home.seo.description"),
    lang: language,
  }

  return (
    <>
      <Layout datos={seo}>
        {breakpoints.xs ? <Videosmart /> : <Video />}
        <Presentacion />
        <Servicios />
        <Nosotros />
        <Comunicaciones />
        <Avit />
        <Navegacion />
        <Servicio />
        <Carrusel />
        <Masinfo />
        <AnchorLink
          to={t(`rutas.home`)}
          className={`${flecha} fixed bottom-14 right-8 md:bottom-28 md:right-10 w-12 h-12 rounded-full border-white border-2 bg-azul flex items-center justify-center transition-all duration-500 z-40 hover:-translate-y-2`}
        >
          <Flecha className="w-1/2" />
        </AnchorLink>
      </Layout>
    </>
  )
}

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
