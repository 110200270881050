import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { StaticImage } from "gatsby-plugin-image"
import BotonPeque from "../BotonPeque"

const Presentacion = () => {
  const { t } = useTranslation()
  return (
    <section className="relative flex flex-col items-center justify-center p-10 lg:p-14 lg:pb-0 w-full">
      <div className="lg:container max-w-8xl md:grid grid-cols-2 items-stretch justify-center pb-0 gap-x-5 ">
        <article>
          <StaticImage
            src="../../images/presentacion.jpg"
            alt="interfaces"
            className="w-full h-full"
          />
        </article>

        <article className="flex flex-col items-center justify-center sm:p-5 px-7 text-blanco ">
          <p className="relative mb-2 text-xl lg:text-3xl before:w-4 before:h-4 before:bg-azul before:absolute before:top-2.5 before:-left-6 font-bold text-azul">
            {t("home.presentacion.inicio")}
          </p>
          <p className="lg:text-xl mb-6 text-negro">
            {t("home.presentacion.inicio2")}
          </p>
          <BotonPeque
            url={t("rutas.contacto")}
            texto={t("home.presentacion.boton")}
          />
        </article>
      </div>
    </section>
  )
}

export default Presentacion
