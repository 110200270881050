import React, { useState } from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import Comunicaciones from "../../assets/comunicaciones.svg"
import Avit from "../../assets/avit.svg"
import Navegacion from "../../assets/navegacion.svg"
import Tecnico from "../../assets/tecnico.svg"

const Servicios = () => {
  const { t } = useTranslation()

  const [primera, setPrimera] = useState(true)
  const [segunda, setSegunda] = useState(false)
  const [tercera, setTercera] = useState(false)
  const [cuarta, setCuarta] = useState(false)

  const handleMover = index => {
    if (index === 0) {
      setPrimera(true)
      setSegunda(false)
      setTercera(false)
      setCuarta(false)
    } else if (index === 1) {
      setPrimera(false)
      setSegunda(true)
      setTercera(false)
      setCuarta(false)
    } else if (index === 2) {
      setPrimera(false)
      setSegunda(false)
      setTercera(true)
      setCuarta(false)
    } else if (index === 3) {
      setPrimera(false)
      setSegunda(false)
      setTercera(false)
      setCuarta(true)
    }
  }

  const claseIcono =
    "h-8 w-8 md:h-12 md:w-12 mr-3 group-hover:scale-110 transform transition-all duration-100"

  const iconos = [
    <Comunicaciones className={claseIcono} alt="icono" />,
    <Avit className={claseIcono} alt="icono" />,
    <Navegacion className={claseIcono} alt="icono" />,
    <Tecnico className={claseIcono} alt="icono" />,
  ]
  const rutas = [
    t("rutas.comunicaciones"),
    t("rutas.avit"),
    t("rutas.navegacion"),
    t("rutas.servicio"),
  ]
  return (
    <section className="flex flex-col items-center justify-center p-5 bg-azul-dark md:bg-blanco ">
      <div className="w-full mx-auto grid grid-cols-2 lg:container lg:h-[500px]">
        <article className="hidden md:flex flex-row flex-nowrap overflow-hidden h-auto rounded-tl rounded-bl ">
          {primera ? (
            <StaticImage
              src="../../images/slider/1.jpg"
              alt="Slider image"
              className="animate-aparece h-full object-contain "
            />
          ) : null}
          {segunda ? (
            <StaticImage
              src="../../images/slider/2.jpeg"
              alt="Slider image"
              className="animate-aparece h-full object-contain "
            />
          ) : null}
          {tercera ? (
            <StaticImage
              src="../../images/slider/3.jpg"
              alt="Slider image"
              className="animate-aparece h-full object-contain "
            />
          ) : null}
          {cuarta ? (
            <StaticImage
              src="../../images/slider/4.jpg"
              alt="Slider image"
              className="animate-aparece h-full object-contain "
            />
          ) : null}
        </article>
        <article className="flex flex-col items-start justify-center p-4 w-full md:p-10 col-span-2 md:col-span-1 md:bg-gradient-to-tr from-azul-dark to-azul lg:rounded-tr lg:rounded-br">
          <h2
            className="text-blanco text-4xl md:text-5xl mb-4 ml-10 md:ml-0 md:mb-7 block font-black"
            dangerouslySetInnerHTML={{ __html: t("home.servicios.titulo") }}
          />
          <article className="text-blanco cursor-default w-full">
            {iconos.map((icono, index) => (
              <Link
                key={index}
                to={rutas[index]}
                className="block border-b border-azulClaro last-of-type:border-none"
              >
                <button
                  className="flex flex-row items-center text-left w-full py-2 group text-2xl md:text-xl md:uppercase hover:text-azulClaro transition duration-200  hover:bg-azulClaro hover:bg-opacity-10 appearance-none"
                  key={index}
                  onMouseOver={() => handleMover(index)}
                  onFocus={() => handleMover(index)}
                >
                  <span className="mr-2"> {icono}</span>

                  {t(`home.servicios.lista.${index}`)}
                </button>
              </Link>
            ))}
          </article>
        </article>
      </div>
    </section>
  )
}

export default Servicios
