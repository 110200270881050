import React from "react"
import Logos from "../../assets/carrusel.svg"

const Carrusel = () => {
  return (
    <section className="relative container h-60 overflow-hidden mt-10 mb-5 md:before:w-32 before:h-full before:bg-gradient-to-r from-blanco to-transparent before:block before:absolute before:z-10 md:after:w-32 after:h-full after:bg-gradient-to-l after:block after:absolute after:right-0 after:z-10 ">
      <div className="absolute  z-10 h-full w-full"></div>
      <Logos className="absolute left-0 animate-carrusel" alt="Carroussel" />
    </section>
  )
}

export default Carrusel
