import React, { useState, useEffect } from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import Calidad from "../../assets/calidad.svg"
import Orientacion from "../../assets/orientacion.svg"
import Soluciones from "../../assets/soluciones.svg"
import { StaticImage } from "gatsby-plugin-image"

const Nosotros = () => {
  const { t } = useTranslation()
  const iconos = [
    <Calidad className="w-1/3 md:w-1/2 lg:w-24 xl:w-1/4 h-auto mb-2" />,
    <Orientacion className="w-1/3 md:w-1/2 lg:w-24 xl:w-1/4 h-auto mb-2 " />,
    <Soluciones className="w-1/3 md:w-1/2 lg:w-24 xl:w-1/4 h-auto mb-2 " />,
  ]
  const [nosotros, setNosotros] = useState("")

  useEffect(() => {
    window.addEventListener("scroll", handleNosotros)
  }, [])

  const handleNosotros = () => {
    const position = window.pageYOffset

    if (position >= 1500) {
      setNosotros("md:animate-nosotros")
    } else {
      setNosotros("")
    }
  }

  return (
    <section
      className="flex flex-col justify-center items-center lg:px-14"
      id="nosotros"
    >
      <article className="container max-w-8xl grid grid-cols-2 gap-x-10 ">
        <div className="col-span-2 md:col-span-1 px-10 mt-5 md:p-0 md:mt-0 ">
          <h1
            className={`inline-block text-xl bg-azulClaro text-blanco font-bold px-8 py-2 rounded-lg  mb-5 transform-gpu ${nosotros}`}
          >
            {t("home.nosotros.titulo")}
          </h1>
          <div
            dangerouslySetInnerHTML={{ __html: t("home.nosotros.titulo2") }}
          />

          <p
            className="relative text-azul-dark mt-5 pb-5 before:block before:w-3 before:h-3 before:bg-azul before:absolute before:top-2 before:-left-5 before:z-30 lg:col-span-2 lg:text-xl"
            dangerouslySetInnerHTML={{ __html: t("home.nosotros.texto") }}
          />
        </div>
        <StaticImage
          src="../../images/nosotros.jpg"
          className="w-full col-span-2 row-start-1 h-56 sm:h-auto max-h-[400px] md:col-span-1 md:rounded"
          alt="technology"
        />
        <div className="col-span-2 flex flex-col lg:grid grid-cols-3 md:mt-7 bg-azulClaro-light rounded md:p-10 lg:gap-5 ">
          {iconos.map((icono, index) => (
            <div
              className="flex flex-col justify-start items-start w-full p-8  md:w-1/2 lg:w-full lg:px-14 bg-blanco lg:border-4 border-azul"
              key={index}
            >
              {icono}
              <h2 className="font-bold text-2xl text-azul mb-2">
                {t(`home.nosotros.icon${index}`)}
              </h2>
              <p
                className="text-azul"
                dangerouslySetInnerHTML={{
                  __html: t(`home.nosotros.texto${index}`),
                }}
              />
            </div>
          ))}
        </div>
      </article>
    </section>
  )
}

export default Nosotros
